<script setup>
import { ref, reactive, onMounted, defineEmits } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'
import dayjs from 'dayjs'
import errorHandler from '@/helpers/errorHandler'
import FxReportContainer from '@/components/FxReportContainer'
import LaporanKeuanganService from '@/services/LaporanKeuanganService'
import useVuelidate from '@vuelidate/core'

const toast = useToast()
const laporanService = new LaporanKeuanganService()

let isLoadingExport = ref(false)

let form = reactive({
  date_from: null,
  date_to: null,
  kas: null,
})

let submitted = ref(false)

const rules = {
  date_from: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
  date_to: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

onMounted(async () => {
  // const res = []
})

function search(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  onExport()
}

function onExport() {
  isLoadingExport.value = true
  const startDate = dayjs(form.date_from).format('YYYY-MM-DD')
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')
  const kasId = form.kas

  laporanService
    .exportLaporanKasPemasukan(kasId, startDate, endDate)
    .then((res) => {
      isLoadingExport.value = false
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'penerimaan-kas.pdf')
      document.body.appendChild(link)
      link.click()
    })

    .catch((err) => {
      errorHandler(err, 'Data Penerimaan Kas', toast)
    })
    .finally(() => (isLoadingExport.value = false))
}

function onToggle() {
  emit('toggle')
}

function resetForm() {
  submitted.value = false
  form.kas = null
  form.date_from = null
  form.date_to = null
}
</script>

<template>
  <fx-report-container
    title="Laba Rugi"
    :loading="isLoadingExport"
    @toggle="onToggle"
    @export="onExport"
  >
    <template #content>
      <form @submit.prevent="search(!v$.$invalid)">
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_from.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_from.$invalid && submitted,
              }"
              placeholder="Tanggal (dari)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_from.$invalid && submitted) ||
                v$.date_from.$pending.$response
              "
              class="p-error"
              >{{ v$.date_from.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_to.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_to.$invalid && submitted,
              }"
              placeholder="Tanggal (s/d)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_to.$invalid && submitted) ||
                v$.date_to.$pending.$response
              "
              class="p-error"
              >{{ v$.date_to.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <Button
              :loading="isLoadingExport"
              type="submit"
              icon="pi pi-file-pdf"
              label="Cetak"
            />
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2"
              @click="resetForm"
            />
          </div>
        </div>
      </form>
    </template>
  </fx-report-container>
</template>
